/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from "@sentry/vue";
import { AxiosRequestConfig, Method } from "axios";

import axios from "../axios";
import { useAuthStore } from "../store/auth";
import {
  convertDateStringToISOInObject,
  convertISOToDateInObject,
  convertISOToDateStringInObject,
  toast,
} from "../utils";

export interface APIError {
  response: { message: string };
  statusCode: number;
  isAPIError: boolean;
}

interface ApiCallRequest<T = any> {
  method: Method;
  path: string;
  data?: T;
  config?: AxiosRequestConfig;
  token?: string;
}

export interface ObjectLiteral {
  [key: string]: any;
}

async function requestErrorHandler(error: any) {
  try {
    if (!error.response) {
      throw {
        response: { message: "Verifique sua conexão com a internet e tente novamente." },
        isAPIError: true,
      } as APIError;
    }

    const authStore = useAuthStore();

    switch (error.response.status) {
      case 401:
        authStore.cleanSession();
        toast("error", "Sua sessão expirou. Faça o login novamente.");

        return;
      default:
        throw {
          response: error.response.data,
          statusCode: error.response.status,
          isAPIError: true,
        } as APIError;
    }
  } catch (handledError) {
    Sentry.captureException(handledError);

    throw handledError;
  }
}

async function apiCall({ method, path, data, config, token }: ApiCallRequest): Promise<any> {
  const accessToken = token ?? useAuthStore().accessToken;

  const requestConfig: AxiosRequestConfig = {
    method,
    url: path,
    data,
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    ...config,
  };

  try {
    convertDateStringToISOInObject(requestConfig.data, ["birthDate"]);

    const response = await axios(requestConfig);

    if (response.data) {
      convertISOToDateStringInObject(response.data, ["birthDate"]);
      convertISOToDateInObject(response.data, [
        "createdDate",
        "updatedDate",
        "approvalExpirationDate",
        "createdAt",
        "paymentDate",
      ]);

      return response.data;
    }

    return;
  } catch (error) {
    return requestErrorHandler(error);
  }
}

export async function apiGet<T = any, K extends ObjectLiteral = any>(
  payload: Omit<ApiCallRequest<K>, "method" | "data">
): Promise<T> {
  return apiCall({ method: "GET", ...payload });
}

export async function apiPost<T = any, K extends ObjectLiteral = any>(
  payload: Omit<ApiCallRequest<K>, "method">
): Promise<T> {
  return apiCall({ method: "POST", ...payload });
}

export async function apiPatch<T = any, K extends ObjectLiteral = any>(
  payload: Omit<ApiCallRequest<K>, "method">
): Promise<T> {
  return apiCall({ method: "PATCH", ...payload });
}

export async function apiDelete<T = any, K extends ObjectLiteral = any>(
  payload: Omit<ApiCallRequest<K>, "method">
): Promise<T> {
  return apiCall({ method: "DELETE", ...payload });
}
