import { setupWorker, rest } from "msw";

import { joinPaths } from "../utils";

const baseURL = import.meta.env.VITE_API_BASE_URL;

let cards = [
  { id: "1", label: "Cartão visa", brand: "Visa", last4CardNumber: "4241" },
  { id: "2", label: "Cartão master", brand: "MasterCard", last4CardNumber: "4242" },
  { id: "3", label: "Cartão black", brand: "Visa", last4CardNumber: "4243" },
  { id: "4", label: "Cartão silver", brand: "Visa", last4CardNumber: "4244" },
];

const mocks = [
  rest.post<{
    name: string;
  }>(joinPaths(baseURL, "mockEndpoint"), (req, res, ctx) => {
    const { name } = req.body;

    if (name) {
      return res(ctx.status(201), ctx.json({ name }), ctx.delay(1000));
    }

    return res(ctx.status(400), ctx.json({ message: "Mensagem de erro" }));
  }),

  rest.get(joinPaths(baseURL, "app/credit_card"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(cards), ctx.delay(1000));
  }),

  rest.delete(joinPaths(baseURL, "app/credit_card/:cardId"), (req, res, ctx) => {
    const { cardId } = req.params;

    if (cardId === "3") {
      return res(ctx.status(400), ctx.json({ message: "Erro ao deletar o cartão." }));
    }

    cards = cards.filter((card) => card.id !== cardId);

    return res(ctx.status(200), ctx.delay(1000));
  }),
];

export default setupWorker(...mocks);
