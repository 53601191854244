import { defineStore } from "pinia";

import { login, logout } from "../api/auth";
import { UserRole } from "../api/models/user";
import router from "../router";
import { toast } from "../utils";

import { useUserStore } from "./user";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => {
    return {
      accessToken:
        localStorage.getItem("accessToken") ??
        (sessionStorage.getItem("accessToken") as string | null),
    };
  },
  getters: {
    isUserLoggedIn(state) {
      return !!state.accessToken;
    },
  },
  actions: {
    setToken({ token, rememberMe }: { token: string | null; rememberMe?: boolean }) {
      if (token === null) {
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("accessToken");
        this.$reset();

        return;
      }

      if (rememberMe) {
        localStorage.setItem("accessToken", token);
      } else {
        sessionStorage.setItem("accessToken", token);
      }

      this.$reset();
    },

    async login({
      email,
      password,
      rememberMe,
    }: {
      email: string;
      password: string;
      rememberMe?: boolean;
    }) {
      const session = await login({ email, password });

      const isAdminTryingToEnterInUserPlatform =
        import.meta.env.VITE_PROJECT_TYPE === "restaurant" &&
        session.user.role !== UserRole.RESTAURANT_USER;

      const isUserTryingToEnterInAdminPlatform =
        import.meta.env.VITE_PROJECT_TYPE === "admin" &&
        session.user.role !== UserRole.ADMINISTRATOR;

      if (isUserTryingToEnterInAdminPlatform || isAdminTryingToEnterInUserPlatform) {
        logout({ headers: { Authorization: `Bearer ${session.token}` } });
        toast(
          "error",
          isAdminTryingToEnterInUserPlatform
            ? "Administradores da Tirss não têm acesso ao painel de restaurantes."
            : "Você não tem permissão para realizar esta ação."
        );
        throw Error("USER_ROLE_NOT_ALLOWED");
      }

      this.setToken({ token: session.token, rememberMe });
      const userStore = useUserStore();
      userStore.activeUser = session.user;
      userStore.loadInitialLoggedInRoute();
    },

    logout() {
      logout();
      this.cleanSession();
    },

    cleanSession() {
      this.setToken({ token: null });
      useUserStore().$reset();

      router.push("/login");
    },
  },
});
