<template>
  <img
    v-if="icons[name].type === 'assets'"
    class="block align-middle"
    :style="`width: ${size || 'auto'}; min-width: ${size || 'auto'};`"
    :src="getIcon(`${icons[name].name}`, icons[name].extension, isDisabled)"
    :alt="icons[name].name"
  />

  <span
    v-else
    :class="['block align-middle', icons[name].type]"
    :style="`font-size: ${size || 'inherit'};`"
  >
    {{ icons[name].name }}
  </span>
</template>

<script setup lang="ts">
import { getIcon, IconName } from "../utils";

withDefaults(
  defineProps<{
    name: IconName;
    size?: string;
    isDisabled?: boolean;
  }>(),
  {
    size: undefined,
  }
);

type Icon = {
  [Key in IconName]: {
    name: string;
    type: "assets" | "material-icons" | "material-icons-outlined" | "material-icons-round";
    extension?: "svg" | "png";
  };
};

const icons: Icon = {
  Add: { name: "add", type: "material-icons" },
  ArrowBack: { name: "arrow_back", type: "material-icons-round" },
  ArrowDown: { name: "keyboard_arrow_down", type: "material-icons" },
  ArrowDropDown: { name: "arrow_drop_down", type: "assets", extension: "svg" },
  ArrowDropUp: { name: "arrow_drop_up", type: "assets", extension: "svg" },
  ArrowLeft: { name: "keyboard_arrow_left", type: "material-icons" },
  ArrowRight: { name: "keyboard_arrow_right", type: "material-icons" },
  BarCode: { name: "bar_code", type: "assets", extension: "svg" },
  Block: { name: "block", type: "material-icons" },
  Camera: { name: "camera_alt", type: "material-icons-outlined" },
  Card: { name: "credit_card", type: "assets", extension: "svg" },
  Check: { name: "check", type: "material-icons" },
  Clock: { name: "clock", type: "assets", extension: "svg" },
  Close: { name: "close", type: "material-icons" },
  CopyContent: { name: "content_copy", type: "material-icons" },
  Correct: { name: "correct", type: "assets", extension: "svg" },
  Delete: { name: "delete", type: "material-icons-outlined" },
  DragHandle: { name: "drag_handle", type: "material-icons" },
  Error: { name: "error_outline", type: "material-icons-outlined" },
  ErrorX: { name: "error", type: "assets", extension: "svg" },
  Exclamation: { name: "priority_high", type: "material-icons" },
  Facebook: { name: "facebook", type: "assets", extension: "svg" },
  Filter: { name: "filter", type: "assets", extension: "svg" },
  GoBack: { name: "arrow_back", type: "material-icons" },
  Info: { name: "info", type: "material-icons-outlined" },
  Instagram: { name: "instagram", type: "assets", extension: "svg" },
  Logout: { name: "logout", type: "material-icons" },
  Menu: { name: "menu", type: "material-icons" },
  NavCategories: { name: "nav_categories", type: "assets", extension: "svg" },
  NavClients: { name: "nav_clients", type: "assets", extension: "svg" },
  NavComplaints: { name: "nav_complaints", type: "assets", extension: "svg" },
  NavFinance: { name: "nav_finance", type: "assets", extension: "svg" },
  NavReports: { name: "nav_reports", type: "assets", extension: "svg" },
  NavImageBank: { name: "nav_image_bank", type: "assets", extension: "svg" },
  NavOrders: { name: "nav_orders", type: "assets", extension: "svg" },
  NavRestaurant: { name: "nav_restaurant", type: "assets", extension: "svg" },
  NavRestaurantMenu: { name: "nav_menu", type: "assets", extension: "svg" },
  NavReviews: { name: "nav_reviews", type: "assets", extension: "svg" },
  NavSettings: { name: "nav_settings", type: "assets", extension: "svg" },
  Orders: { name: "orders", type: "assets", extension: "svg" },
  PasswordVisibility: { name: "visibility", type: "material-icons-outlined" },
  PasswordVisibilityOff: { name: "visibility_off", type: "material-icons-outlined" },
  Pencil: { name: "edit", type: "material-icons-outlined" },
  Percent: { name: "percent", type: "material-icons" },
  Pix: { name: "pix", type: "material-icons" },
  QRCode: { name: "qr_code", type: "assets", extension: "svg" },
  Question: { name: "question", type: "assets", extension: "svg" },
  Remove: { name: "remove", type: "material-icons" },
  RemoveCircle: { name: "remove_circle_outlined", type: "material-icons" },
  Restaurant: { name: "restaurant", type: "assets", extension: "svg" },
  RestaurantMenu: { name: "restaurant_menu", type: "assets", extension: "svg" },
  Sad: { name: "sentiment_dissatisfied", type: "material-icons-outlined" },
  Search: { name: "search", type: "material-icons" },
  Send: { name: "send", type: "assets", extension: "svg" },
  SortBy: { name: "swap_vert", type: "assets", extension: "svg" },
  Star: { name: "star", type: "assets", extension: "svg" },
  Success: { name: "check_circle", type: "material-icons-outlined" },
  TirssLogo: { name: "logo_icon_white", type: "assets", extension: "svg" },
  Upload: { name: "upload", type: "material-icons" },
  Gift: { name: "gift", type: "assets", extension: "svg" },
};

// https://marella.me/material-icons/demo/
</script>
