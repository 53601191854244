import { RouteRecordRaw } from "vue-router";

import { Permission } from "../api/models/user";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: "/login" },
  //restaurantes:
  {
    path: "/restaurantes",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Solicitações", path: "/restaurantes/solicitacoes" },
        { name: "Aguardando Pagar.me", path: "/restaurantes/aguardando-pagarme" },
        { name: "Restaurantes cadastrados", path: "/restaurantes/cadastrados" },
        { name: "Solicitações recusadas", path: "/restaurantes/solicitacoes-recusadas" },
      ],
    },
    children: [
      {
        path: "solicitacoes/:id?",
        name: "Solicitações",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () =>
            import("../views/admin/restaurants/solicitation/RestaurantSolicitationForm.vue"),
          table: () =>
            import("../views/admin/restaurants/solicitation/RestaurantSolicitationTable.vue"),
        },
        meta: { requiresAuth: true, priority: 1, permission: Permission.RESTAURANTS },
      },
      {
        path: "aguardando-pagarme/:id?",
        name: "Aguardando Pagar.me",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () =>
            import("../views/admin/restaurants/waitingPagarme/RestaurantWaitingDetails.vue"),
          table: () =>
            import("../views/admin/restaurants/waitingPagarme/RestaurantWaitingView.vue"),
        },
        meta: { requiresAuth: true, priority: 1, permission: Permission.RESTAURANTS },
      },
      {
        path: "cadastrados/:id?",
        name: "Cadastrados",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/restaurants/registered/RestaurantRegisteredForm.vue"),
          table: () =>
            import("../views/admin/restaurants/registered/RestaurantRegisteredTable.vue"),
        },
        meta: { requiresAuth: true, priority: 1, permission: Permission.RESTAURANTS },
      },
      {
        path: ":restaurantId/pedidos/:id?",
        name: "Pedidos",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/restaurants/registered/order/OrderForm.vue"),
          table: () => import("../views/admin/restaurants/registered/order/OrderTable.vue"),
        },
        meta: { requiresAuth: true, priority: 1, permission: Permission.RESTAURANTS },
      },
      {
        path: "solicitacoes-recusadas/:id?",
        name: "Recusadas",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () =>
            import(
              "../views/admin/restaurants/solicitationRefused/RestaurantSolicitationRefusedForm.vue"
            ),
          table: () =>
            import(
              "../views/admin/restaurants/solicitationRefused/RestaurantSolicitationRefusedTable.vue"
            ),
        },
        meta: { requiresAuth: true, priority: 1, permission: Permission.RESTAURANTS },
      },
    ],
  },
  //financeiro:
  {
    path: "/financeiro",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Comissões da Tirss", path: "/financeiro/comissoes" },
        { name: "Estornos", path: "/financeiro/estornos" },
        { name: "Mensalidades Restaurantes", path: "/financeiro/historico-mensalidades" },
        { name: "Mensalidades Vouchers", path: "/financeiro/vouchers" },
      ],
    },
    children: [
      {
        path: "comissoes/:id?",
        name: "Comissões",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/financial/comissions/ComissionsDetails.vue"),
          table: () => import("../views/admin/financial/comissions/ComissionsTable.vue"),
        },
        meta: { requiresAuth: true, priority: 3, permission: Permission.FINANCIAL },
      },
      {
        path: "comissoes/:restaurantId/pedido/:id?",
        name: "Pedido Comissão",
        component: () => import("../views/admin/financial/comissions/ComissionOrderDetails.vue"),
        meta: { requiresAuth: true, priority: 3, permission: Permission.FINANCIAL },
      },
      {
        path: "estornos/:id?",
        name: "Estornos",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/financial/refunds/RefundsDetails.vue"),
          table: () => import("../views/admin/financial/refunds/RefundsTable.vue"),
        },
        meta: { requiresAuth: true, priority: 3, permission: Permission.FINANCIAL },
      },
      {
        path: "historico-mensalidades/:id?",
        name: "Mensalidades Restaurantes",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () =>
            import("../views/admin/financial/subscriptions/SubscriptionsHistoryDetails.vue"),
          table: () =>
            import("../views/admin/financial/subscriptions/SubscriptionsHistoryTable.vue"),
        },
        meta: { requiresAuth: true, priority: 3, permission: Permission.FINANCIAL },
      },
      {
        path: "vouchers/:id?",
        name: "Mensalidades Vouchers",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/financial/vouchers/VouchersForm.vue"),
          table: () => import("../views/admin/financial/vouchers/VouchersTable.vue"),
        },
        meta: { requiresAuth: true, priority: 3, permission: Permission.FINANCIAL },
      },
      {
        path: "vouchers/:restaurantId/pedido/:id?",
        name: "Pedido Voucher",
        component: () => import("../views/admin/financial/vouchers/VouchersOrderView.vue"),
        meta: { requiresAuth: true, priority: 3, permission: Permission.FINANCIAL },
      },
    ],
  },
  //clientes, categorias, banco de imagens, contestações:
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    children: [
      //clientes
      {
        path: "clientes/:id?",
        name: "Clientes",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/customer/CustomerForm.vue"),
          table: () => import("../views/admin/customer/CustomerTable.vue"),
        },
        meta: { requiresAuth: true, priority: 2, permission: Permission.CUSTOMERS },
      },
      //categorias
      {
        path: "categorias/:id?",
        name: "Categorias",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/category/CategoryForm.vue"),
          table: () => import("../views/admin/category/CategoryTable.vue"),
        },
        meta: { requiresAuth: true, priority: 4, permission: Permission.CATEGORIES },
      },
      //banco de imagens
      {
        path: "banco-de-imagens/:id?",
        name: "Banco de imagens",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/imageBank/ImageBankForm.vue"),
          table: () => import("../views/admin/imageBank/ImageBankTable.vue"),
        },

        meta: { requiresAuth: true, priority: 5, permission: Permission.IMAGE_BANK },
      },
      //contestações
      {
        path: "contestacoes/:id?",
        name: "Contestações",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/admin/complaints/ComplaintsAdmDetails.vue"),
          table: () => import("../views/admin/complaints/ComplaintsAdmView.vue"),
        },
        //TODO verificar por que a permission COMPLAINT não funcionou
        meta: { requiresAuth: true, priority: 6, permission: Permission.COMPLAINTS },
      },
    ],
  },
  //configurações:
  {
    path: "/",
    component: () => import("../components/LayoutNavbarVertical.vue"),
    props: {
      tabItems: [
        { name: "Dados pessoais", path: "/minha-conta" },
        { name: "Usuários do painel", path: "/usuarios" },
        { name: "Configurações dos restaurantes", path: "/configuracoes" },
      ],
    },
    children: [
      {
        path: "minha-conta",
        name: "Minha conta",
        component: () => import("../views/settings/MyAccount.vue"),
        meta: { requiresAuth: true, priority: 7 },
      },
      {
        path: "usuarios/:id?",
        name: "Usuários",
        component: () => import("../components/Crud.vue"),
        props: {
          form: () => import("../views/settings/users/UserForm.vue"),
          table: () => import("../views/settings/users/UserTable.vue"),
        },
        meta: { requiresAuth: true, priority: 8, permission: Permission.USERS },
      },
      {
        path: "configuracoes",
        name: "Configurações",
        component: () => import("../views/settings/RestaurantsConfig.vue"),
        meta: { requiresAuth: true, priority: 6 },
      },
    ],
  },
];

export default routes;
