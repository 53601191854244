import { defineStore } from "pinia";

import { getProfile } from "../api/me";
import { User } from "../api/models/user";
import { flatRoutes } from "../router";

import { useAuthStore } from "./auth";

export const useUserStore = defineStore({
  id: "user",
  state: () => {
    return {
      isLoading: false,
      hasNetworkError: false,
      activeUser: null as User | null,
      initialLoggedInRoute: "/minha-conta",
    };
  },
  actions: {
    loadInitialLoggedInRoute() {
      // Seleciona a rota de maior prioridade (menor número) que o usuário tem permissão.
      let priority = 1000;

      flatRoutes.forEach(({ path, meta }) => {
        if (
          (meta?.priority || meta?.priority === 0) &&
          (!meta?.permission || this.activeUser?.permissions?.includes(meta.permission)) &&
          meta.priority < priority
        ) {
          priority = meta.priority;
          this.initialLoggedInRoute = path.replace("/:id?", "");
        }
      });
    },

    async loadUserData() {
      if (!this.activeUser && useAuthStore().accessToken) {
        try {
          this.isLoading = true;

          this.activeUser = await getProfile();
          this.loadInitialLoggedInRoute();
        } catch (_) {
          this.hasNetworkError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
});
