import dynamicRoutes from "@dynamicRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { useAuthStore } from "../store/auth";
import { useUserStore } from "../store/user";

const sharedRoutes: RouteRecordRaw[] = [
  {
    path: "/auth", //utilizado prefixo auth para sobrescrever a path "/",
    //mas não aparecerá nas rotas children, pois elas começam com "/", portanto ignoram esse prefixo

    redirect: "/login", // caso a rota root "/auth" seja chamada será direcionada para o login
    component: () => import("../components/LayoutAuth.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
      },
      {
        path: "/redefinicao-de-senha",
        name: "Redefinição senha",
        component: () => import("../components/FormRequestPasswordReset.vue"),
      },
      {
        path: "/redefinir-senha",
        name: "Redefinir senha",
        component: () => import("../views/ResetPassword.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        name: "Não encontrada",
        component: () => import("../views/Error404.vue"),
      },
    ],
  },
];

const routes = [...sharedRoutes, ...dynamicRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const navbarHeight = document.getElementById("navbar")?.clientHeight ?? 0;

      return {
        el: to.hash,
        top: navbarHeight,
        behavior: "smooth",
      };
    }

    return { top: 0 };
  },
});

function generateFlatRoutes(rawRoutes: RouteRecordRaw[], parentPath?: string) {
  const _flatRoutes: RouteRecordRaw[] = [];

  rawRoutes.forEach((route) => {
    if (route.children) {
      _flatRoutes.push(...generateFlatRoutes(route.children, route.path));
    } else {
      if (parentPath) {
        route.path = `${parentPath}/${route.path}`.replace(new RegExp("/{2,}", "gm"), "/");
      }
      _flatRoutes.push(route);
    }
  });

  return _flatRoutes;
}

export const flatRoutes = generateFlatRoutes(routes);

router.beforeEach(async (to) => {
  document.title = to.name ? `Tirss | ${to.name.toString()}` : "Tirss";

  if (to.name === "Não encontrada") {
    return;
  }

  const authStore = useAuthStore();
  const userStore = useUserStore();

  if (to.meta.requiresAuth && !authStore.isUserLoggedIn) {
    return "/login";
  }

  await userStore.loadUserData();

  if (
    (!to.meta.requiresAuth && authStore.isUserLoggedIn) ||
    (to.meta.permission && !userStore.activeUser?.permissions?.includes(to.meta.permission))
  ) {
    return userStore.initialLoggedInRoute;
  }
});

export default router;
