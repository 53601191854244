import { h } from "vue";
import { PluginOptions, POSITION, useToast } from "vue-toastification";

import Icon from "../components/Icon.vue";

export function toast(type: "error" | "success" | "info", text: string) {
  const _toast = useToast();

  const toastBaseOptions: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    hideProgressBar: false,
    closeButton: false,
  };

  switch (type) {
    case "error": {
      _toast(text, {
        ...toastBaseOptions,
        icon: h(Icon, { name: "Error", size: "24px" }),
        toastClassName: "bg-error w-80 rounded-sm",
        bodyClassName: "text-white",
      });

      break;
    }

    case "success": {
      _toast(text, {
        ...toastBaseOptions,
        icon: h(Icon, { name: "Success", size: "24px" }),
        toastClassName: "bg-success w-80 rounded-sm",
        bodyClassName: "text-white",
      });

      break;
    }

    default: {
      _toast(text, {
        ...toastBaseOptions,
        icon: h(Icon, { name: "Info", size: "24px" }),
        toastClassName: "bg-info w-80 rounded-sm text-body",
        bodyClassName: "text-body",
      });

      break;
    }
  }
}
