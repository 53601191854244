import { BaseEntity } from "./baseEntity";
import { ComplaintStatusEnum } from "./complaint";
import { CardBrandEnum, RefundStatusEnum } from "./financial";
import { Restaurant } from "./restaurant";
import { IReview } from "./review";
import { User, UserRole } from "./user";

export enum PaymentMethod {
  CREDIT_CARD = "CREDIT_CARD",
  PIX = "PIX",
  BOLETO = "BOLETO",
}

export enum PaymentMethodTranslated {
  CREDIT_CARD = "Cartão de crédito",
  PIX = "PIX",
  BOLETO = "Boleto",
}

export interface OrderStatusStep {
  status: OrderStatus;
  updatedDate: Date;
}

export enum OrderDestination {
  TABLE = "TABLE",
  COUNTER = "COUNTER",
  GIFT = "GIFT",
}

export const OrderDestinationTranslated = {
  [OrderDestination.TABLE]: "Mesa",
  [OrderDestination.COUNTER]: "Balcão",
  [OrderDestination.GIFT]: "Presente (Mesa)",
};

export interface Order extends BaseEntity {
  restaurant: Restaurant;
  user: User;
  items: OrderItem[];
  review: IReview;
  code: string;
  status: OrderStatus;
  statusSteps: OrderStatusStep[];
  tableNumber?: string | null;
  destination: OrderDestination;
  paymentMethod: PaymentMethod;
  creditCard4LastNumbers?: string | null;
  creditCardBrand?: CardBrandEnum | null;
  pixQrCode?: string | null;
  pixExpirationDate?: Date | null;
  price: number;
  fullPrice: number;
  serviceFee: number;
  responsibleForCancellation: UserRole | null;
  cancellationDate?: Date | null;
  refundStatus: RefundStatusEnum;
  complaintStatus: ComplaintStatusEnum;
  isComplaintSolved: boolean;
  appFee: number;
  fee: number;
  itemsQuantity?: number;
  commissionAmount?: number;
  cardBrand: string;
}

export interface OrderItem extends BaseEntity {
  amount: number;
  extras: OrderExtra[];
  name: string;
  description: string;
  imageUrl?: string | null;
  price: number;
  note?: string;
}

export interface OrderExtra extends BaseEntity {
  amount: number;
  name: string;
  description?: string | null;
  imageUrl?: string | null;
  price?: number | null;
}

export interface SummaryOrdersResponse {
  ordersPerPeriod: number;
  createdAt: Date;
}

export interface UpdateOrderStatusRequest {
  updatedDate: Date;
  incomingStatus: OrderStatus;
}

export interface CancelOrderStatusRequest {
  updatedDate: Date;
}

export enum OrderStatus {
  PENDING_APPROVAL = "PENDING_APPROVAL",
  PREPARING_ORDER = "PREPARING_ORDER",
  DELIVERING = "DELIVERING",
  DELIVERED = "DELIVERED",
  CANCELED = "CANCELED",
  WAITING_REFUND = "WAITING_REFUND",
}

export class OrderStatusTranslated {
  static readonly [OrderStatus.PENDING_APPROVAL] = "Pedido solicitado";
  static readonly [OrderStatus.PREPARING_ORDER] = "Preparando pedido";
  static readonly [OrderStatus.DELIVERING] = "Entregando pedido"; //A frase vai depender do OrderDestination
  static readonly [OrderStatus.DELIVERED] = "Entregue";
  static readonly [OrderStatus.WAITING_REFUND] = "Aguardando estorno";
  static readonly [OrderStatus.CANCELED] = "Pedido cancelado";

  static getText(status: OrderStatus, destination: OrderDestination): string {
    if (status === OrderStatus.DELIVERING) {
      return destination === OrderDestination.COUNTER
        ? "Pronto para retirada"
        : "Pedido sendo levado até a mesa";
    }

    return OrderStatusTranslated[status];
  }
}
