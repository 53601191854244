import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { Router } from "vue-router";

import { useUserStore } from "../store/user";

export function initializeSentry(app: App, router: Router) {
  const { VITE_SENTRY_DSN, VITE_ENVIRONMENT, VITE_API_BASE_URL } = import.meta.env;

  if (!VITE_SENTRY_DSN || VITE_ENVIRONMENT === "local") {
    return;
  }

  Sentry.init({
    app,
    dsn: VITE_SENTRY_DSN,
    tracePropagationTargets: [VITE_API_BASE_URL],
    _experiments: {
      enableInteractions: true,
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    environment: VITE_ENVIRONMENT,
    beforeSend: attachUserToEvent,
    beforeSendTransaction: attachUserToEvent,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function attachUserToEvent(event: Sentry.Event): Sentry.Event {
  const user = useUserStore().activeUser;

  event.user = {
    id: user?.id,
    email: user?.email,
  };

  return event;
}
