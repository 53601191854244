import { OrderStatus } from "../api/models/order";

export const documents = {
  termsOfUse: "https://static.espresso-cloud.com/exemplos/exemplo_termos-de-uso.pdf",
  privacyPolicy: "https://static.espresso-cloud.com/exemplos/exemplo_politica-de-privacidade.pdf",
};

export const socialInfo = {
  // phone: "(11) 91234-5678",
  facebook: "https://www.facebook.com/",
  instagram: "https://instagram.com/",
  email: "atendimento@tirss.com.br",
};

export const BrazilianStates = [
  { value: "AC", name: "Acre" },
  { value: "AL", name: "Alagoas" },
  { value: "AM", name: "Amazonas" },
  { value: "AP", name: "Amapá" },
  { value: "BA", name: "Bahia" },
  { value: "CE", name: "Ceará" },
  { value: "DF", name: "Distrito Federal" },
  { value: "ES", name: "Espírito Santo" },
  { value: "GO", name: "Goiás" },
  { value: "MA", name: "Maranhão" },
  { value: "MG", name: "Minas Gerais" },
  { value: "MS", name: "Mato Grosso do Sul" },
  { value: "MT", name: "Mato Grosso" },
  { value: "PA", name: "Pará" },
  { value: "PB", name: "Paraíba" },
  { value: "PE", name: "Pernambuco" },
  { value: "PI", name: "Piauí" },
  { value: "PR", name: "Paraná" },
  { value: "RJ", name: "Rio de Janeiro" },
  { value: "RN", name: "Rio Grande do Norte" },
  { value: "RO", name: "Rondônia" },
  { value: "RR", name: "Roraima" },
  { value: "RS", name: "Rio Grande do Sul" },
  { value: "SC", name: "Santa Catarina" },
  { value: "SE", name: "Sergipe" },
  { value: "SP", name: "São Paulo" },
  { value: "TO", name: "Tocantins" },
];

export const banks = [
  { label: "BCO DO BRASIL S.A.", value: "001" },
  { label: "BCO DO NORDESTE DO BRASIL S.A.", value: "004" },
  { label: "BCO SANTANDER (BRASIL) S.A.", value: "033" },
  { label: "Caixa Economica Federal", value: "104" },
  { label: "BCO BRADESCO S.A.", value: "237" },
  { label: "ITAÚ UNIBANCO S.A.", value: "341" },
  { label: "KIRTON BANK", value: "399" },
  { label: "BANCO BTG PACTUAL S.A.", value: "208" },
  { label: "BANCO MASTER", value: "243" },
  { label: "BANCO PAN", value: "623" },
  { label: "BANCO SICOOB S.A.", value: "756" },
  { label: "BCO ABC BRASIL S.A.", value: "246" },
  { label: "BCO AGIBANK S.A.", value: "121" },
  { label: "BCO ALFA S.A.", value: "025" },
  { label: "BCO BANESTES S.A.", value: "021" },
  { label: "BCO BBI S.A.", value: "036" },
  { label: "BCO BMG S.A.", value: "318" },
  { label: "BCO BNP PARIBAS BRASIL S A", value: "752" },
  { label: "BCO BOCOM BBM S.A.", value: "107" },
  { label: "BCO BRADESCO FINANC. S.A.", value: "394" },
  { label: "BCO BS2 S.A.", value: "218" },
  { label: "BCO C6 CONSIG", value: "626" },
  { label: "BCO C6 S.A.", value: "336" },
  { label: "BCO CAIXA GERAL BRASIL S.A.", value: "473" },
  { label: "BCO CCB BRASIL S.A.", value: "320" },
  { label: "BCO CETELEM S.A.", value: "739" },
  { label: "BCO CITIBANK S.A.", value: "745" },
  { label: "BCO COOPERATIVO SICREDI S.A.", value: "748" },
  { label: "BCO CREFISA S.A.", value: "069" },
  { label: "BCO CRÉDIT AGRICOLE BR S.A.", value: "222" },
  { label: "BCO DA AMAZONIA S.A.", value: "003" },
  { label: "BCO DAYCOVAL S.A", value: "707" },
  { label: "BCO DIGIMAIS S.A.", value: "654" },
  { label: "BCO DO EST. DE SE S.A.", value: "047" },
  { label: "BCO DO EST. DO PA S.A.", value: "037" },
  { label: "BCO DO ESTADO DO RS S.A.", value: "041" },
  { label: "BCO FATOR S.A.", value: "265" },
  { label: "BCO GUANABARA S.A.", value: "612" },
  { label: "BCO INDUSTRIAL DO BRASIL S.A.", value: "604" },
  { label: "BCO J.P. MORGAN S.A.", value: "376" },
  { label: "BCO LETSBANK S.A.", value: "630" },
  { label: "BCO LUSO BRASILEIRO S.A.", value: "600" },
  { label: "BCO MERCANTIL DO BRASIL S.A.", value: "389" },
  { label: "BCO MIZUHO S.A.", value: "370" },
  { label: "BCO MODAL S.A.", value: "746" },
  { label: "BCO MORGAN STANLEY S.A.", value: "066" },
  { label: "BCO ORIGINAL DO AGRO S/A", value: "079" },
  { label: "BCO PAULISTA S.A.", value: "611" },
  { label: "BCO RENDIMENTO S.A.", value: "633" },
  { label: "BCO RIBEIRAO PRETO S.A.", value: "741" },
  { label: "BCO SAFRA S.A.", value: "422" },
  { label: "BCO SOCIETE GENERALE BRASIL", value: "366" },
  { label: "BCO SOFISA S.A.", value: "637" },
  { label: "BCO TRIANGULO S.A.", value: "634" },
  { label: "BCO VOTORANTIM S.A.", value: "655" },
  { label: "BCO. J.SAFRA S.A.", value: "074" },
  { label: "BOFA MERRILL LYNCH BM S.A.", value: "755" },
  { label: "BPP IP S.A.", value: "301" },
  { label: "BRB - BCO DE BRASILIA S.A.", value: "070" },
  { label: "BRL TRUST DTVM SA", value: "173" },
  { label: "Banco Inter", value: "077" },
  { label: "Banco Original", value: "212" },
  { label: "Banco Semear", value: "743" },
  { label: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO", value: "114" },
  { label: "CONF NAC COOP CENTRAIS UNICRED", value: "136" },
  { label: "COOPCENTRAL AILOS", value: "085" },
  { label: "CREDISAN CC", value: "089" },
  { label: "CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.", value: "097" },
  { label: "CRESOL CONFEDERAÇÃO", value: "133" },
  { label: "GERENCIANET", value: "364" },
  { label: "HIPERCARD BM S.A.", value: "062" },
  { label: "MERCADO PAGO IP LTDA.", value: "323" },
  { label: "NU PAGAMENTOS - IP", value: "260" },
  { label: "OMNI BANCO S.A.", value: "613" },
  { label: "PAGSEGURO S.A.", value: "290" },
  { label: "PARANA BCO S.A.", value: "254" },
  { label: "POLOCRED SCMEPP LTDA.", value: "093" },
  { label: "SOCIAL BANK S/A", value: "412" },
  { label: "STONE IP S.A.", value: "197" },
  { label: "UNIPRIME CENTRAL CCC LTDA.", value: "099" },
  { label: "UNIPRIME DO BRASIL - CC LTDA.", value: "084" },
];

export const imageBankCategory = ["Cervejas", "Chás", "Molhos", "Refrigerantes", "Sucos", "Outros"];

export const orderStatus = [
  OrderStatus.CANCELED,
  OrderStatus.DELIVERED,
  OrderStatus.DELIVERING,
  OrderStatus.PENDING_APPROVAL,
  OrderStatus.PREPARING_ORDER,
  OrderStatus.WAITING_REFUND,
];

export const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
