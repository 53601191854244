import { APIError } from "../api/_server";

export function getIcon(icon: string, extension?: string, isDisabled = false) {
  return new URL(
    `../assets/icons/${icon}${isDisabled ? "_disabled" : ""}.${extension}`,
    import.meta.url
  ).href;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAPIError(error: any): error is APIError {
  return error.isAPIError;
}
export function getImage(imageSrc: string) {
  return new URL(`/src/assets/${imageSrc}`, import.meta.url).href;
}

export function joinPaths(...paths: string[]) {
  return paths
    .map((path, index) =>
      index === 0 ? path.replace(/\/$/g, "") : path.replace(/\/$/g, "").replace(/^\//g, "")
    )
    .join("/");
}

export const lowerCase = (text: string) => text.toLowerCase();
