import { ButtonVariant } from "../components/Button.vue";

export type IconName =
  | "Add"
  | "ArrowBack"
  | "ArrowDown"
  | "ArrowDropDown"
  | "ArrowDropUp"
  | "ArrowLeft"
  | "ArrowRight"
  | "BarCode"
  | "Block"
  | "Camera"
  | "Card"
  | "Check"
  | "Close"
  | "Clock"
  | "CopyContent"
  | "Correct"
  | "Delete"
  | "DragHandle"
  | "Error"
  | "ErrorX"
  | "Exclamation"
  | "Facebook"
  | "Filter"
  | "GoBack"
  | "Info"
  | "Instagram"
  | "Logout"
  | "Menu"
  | "NavCategories"
  | "NavClients"
  | "NavComplaints"
  | "NavFinance"
  | "NavImageBank"
  | "NavOrders"
  | "NavRestaurant"
  | "NavRestaurantMenu"
  | "NavReviews"
  | "NavReports"
  | "NavSettings"
  | "Orders"
  | "PasswordVisibility"
  | "PasswordVisibilityOff"
  | "Pencil"
  | "Percent"
  | "Pix"
  | "QRCode"
  | "Question"
  | "Remove"
  | "RemoveCircle"
  | "Restaurant"
  | "RestaurantMenu"
  | "Sad"
  | "Search"
  | "Send"
  | "SortBy"
  | "Star"
  | "Success"
  | "TirssLogo"
  | "Upload"
  | "Gift";

export type CalloutType = "success" | "warning" | "info" | "unstyled";

export interface ScheduleDay {
  weekDay: number;
  isActive: boolean;
  times?: {
    startTime: string;
    endTime: string;
  }[];
}

export enum SectionHeaderKind {
  TITLE = "TITLE",
  BUTTONS = "BUTTONS",
  CUSTOM = "CUSTOM",
}

export interface SectionHeaderStructure {
  kind: SectionHeaderKind;
  text?: string;
  subtitle?: string;
  titleCustomClass?: string;
  lineCustomClass?: string;
  hasBackButton?: boolean;
  buttons?: {
    label: string;
    onClick: () => void;
    variant?: ButtonVariant;
    iconOnLeftSide?: IconName;
    iconOnRightSide?: IconName;
    iconSize?: string;
    customClass?: string;
  }[];
  customComponent?: unknown;
}

export type SectionHeaderStructured = { headers: SectionHeaderStructure[] };

export interface CrudTablePlainQuery {
  searchTerm?: string;
  filter?: string;
}
